import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Scrollspy from 'react-scrollspy'

import './styles.scss';

const ScrollNav = () => {
  let scrollNav = React.createRef();

  const onUpdate = (e) => {
    if (e) {
      const isHidden = (scrollNav.classList.contains('hidden-nav') || scrollNav.classList.contains('hidden-nav-no-animation'));
      if (isHidden && e.id != 'home') {
        scrollNav.classList.add('ScrollNav');
        scrollNav.classList.remove('hidden-nav');
        scrollNav.classList.remove('hidden-nav-no-animation');
      } else if (e.id == 'home' && !isHidden) {
        scrollNav.classList.remove('ScrollNav');
        scrollNav.classList.add('hidden-nav');
      }

      // remove old active links
      let links = document.querySelectorAll(`[href*='#']`);
      links.forEach(link => {
        link.classList.remove('custom-active');
        link.classList.remove('active');
      })

      // add active link to the proper event
      let navLink = document.querySelector(`[href*='#${e.id}']`);
      if (navLink) {
        navLink.classList.add('custom-active');
      }
    }
  }

  return (
    <div className="d-none d-md-block hidden-nav-no-animation" id="scroll-nav" ref={ref => (scrollNav = ref)}>
      <Scrollspy
        items={ ['home', 'our-technology', 'development-program', 'team', 'advisors', 'contact'] }
        currentClassName="custom-active"
        onUpdate={onUpdate}
        offset={-90}
        style={{ padding: "0px", marginBottom: "0px" }}>
        <Container style={{ padding: "0px" }}>
          <Navbar expand="lg" variant="custom">
            <Navbar.Brand href="#home">
              <img src="/lutum-logo-positive.svg" height='48px' />      
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto align-items-center" id="main-nav">
                <Nav.Link className='custom-link' href="#our-technology">OUR TECHNOLOGY</Nav.Link>
                <Nav.Link className='custom-link' href="#development-program">DEVELOPMENT PROGRAM</Nav.Link>
                {/* <Nav.Link className='custom-link' href="#team">TEAM</Nav.Link> */}
                {/* <Nav.Link className='custom-link' href="#advisors">ADVISORS</Nav.Link> */}
                <Button variant='outline-dark' href="#contact">CONTACT</Button>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </Scrollspy>
    </div>
  )
}

export default ScrollNav;