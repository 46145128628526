import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import './styles.scss';

const OurTechnology = () => (
  <section id='our-technology'>
    <div className='OurTechnology' data-offset="0">
      <Container>
        <Row>
          <Col xs='12'>
            <h1>Our Technology</h1>
          </Col>
          <Col xs='12'>
            <div className='not-header'>Molecularly Imprinted Polymers</div>
          </Col>
          <Col xs='12' className='diagram'>
            <img src='/printing-diagram.png' className='img-fluid' />
          </Col>
          <Col xs='12' sm='12' md='3'>
            <div className='top-small-border blue' />
            <p className='top-p'>MIPs are similar to biological receptors in the body.</p>
          </Col>
          <Col xs='12' sm='12' md='3'>
            <div className='top-small-border blue' />
            <p className='top-p'>MIPs have very specific binding sites which only bind the target molecule, giving them high specificity.</p>
          </Col>
          <Col xs='12' sm='12' md='3'>
            <div className='top-small-border blue' />
            <p className='top-p'>The sensor measures concentration of the target molecule by change in electrical properties across the sensor.</p>
          </Col>
          <Col xs='12' sm='12' md='3'>
            <div className='top-small-border blue' />
            <p className='top-p'>When external concentration decreases, the MIP will release the target molecule and return to its original state.</p>
          </Col>
          <Col xs='12'>
            <div className='separator' />
          </Col>
          <Col xs='12'>
            <p className='bottom-p'>Solution Case Study: <strong>Biogas</strong></p>
            <h4 className='mb-4'>Lutum developed a proprietary composite material to capture and remove hydrogen sulfide from biogas in partnership with a leading RNG company.</h4>
            <Table responsive bordered size='lg' className='mb-0'>
              <tbody>
                <tr>
                  <th className='bg-light' style={{  verticalAlign: "middle" }}>Product highlights</th>
                  <td><strong>10X-30X more efficient</strong> (depending on moisture level in gas) at removing hydrogen sulfide from landfill gas than carbon media and technologies; permanent capture with no seepage of H2S back into landfill once disposed</td>
                </tr>
                <tr>
                  <th className='bg-light' style={{  verticalAlign: "middle" }}>Capex/Opex</th>
                  <td>Minimal capital expenditures and engineering costs</td>
                </tr>
                <tr>
                  <th className='bg-light' style={{  verticalAlign: "middle" }}>Ease of use</th>
                  <td>Similar to carbon media - works with existing carbon media setups and processes</td>
                </tr>
                <tr>
                  <th className='bg-light' style={{  verticalAlign: "middle" }}>Specialty handling/disposal</th>
                  <td>None - Lutum media chemical bond with H2S creates non-toxic, non-water-soluble material</td>
                </tr>
                <tr>
                  <th className='bg-light' style={{  verticalAlign: "middle" }}>Hydrocarbon loss or cross-contamination</th>
                  <td>None; composite designed specifically for H2S</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
)

export default OurTechnology;